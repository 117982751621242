import React from 'react';

export default class Repositories extends React.Component{
    constructor(props){
        super(props);
    }

    render(){
        return (
            <div>
                
            </div>
        )
    }
}