import React from 'react';

import './Resume.scss';

export default class extends React.Component{
    constructor(params){
        super(params)
    }

    componentDidMount(){
    }

    render(){
        return (
            <div className="resume">
                <div className="resume-container">
                    <div className="resume-page">

                    </div>
                    <div className="resume-page">

                    </div>
                    <div className="resume-page">

                    </div>
                </div>
            </div>
        )
    }
}