import React from 'react';
// import './Coverletter.scss';

export default class CoverLetter extends React.Component{
    constructor(params){
        super(params);
    }
    render(){
        return (
            <div className="cover">
                <div className="cover-letter">

                </div>
            </div>
        )
    }
}